<template>
  <section
    ref="section"
    class="poems-section"
  >
      <div 
        class="photo"
        :style="{
          backgroundImage: 'url(' + photo + ')'
        }"
      ></div>
      <div
        class="frame"
      >
          <p v-html="formattedText"></p>
      </div>
  </section>
</template>

<script>

export default {
  name: 'FramePoem',
  props: ['photo', 'text'],
  data: () => ({
    
  }),
  computed: {
    formattedText () {
      return this.text.replace(/\n/g, '<br>')
    }
  },
  methods: {
    
  },
  mounted () {
    
  },
  created () {
    
  }
}
</script>

<style scoped lang="scss">

.poems-section {
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (min-width: 960px) {
      flex-direction: row;
  }
}
.photo {
    height: 200px;
    width: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (min-width: 960px) {
        margin: 20px 20px 0 20px;
        flex-basis: 300px;
        height: 200px;
    }
}
.frame {
  border: 1px solid #444;
  z-index: 1;
  margin: -15px auto 50px auto;
  width: 320px;
  padding: 25px;
  @media screen and (min-width: 960px) {
      flex-basis: 800px;
      height: 123px;
      margin: -15px 0 0 -340px;
      padding: 15px 15px 15px 375px;
  }
}
p {
    margin: 15px 0 20px 0;
    font-size: 1.3rem;
    line-height: 2.8rem;
    color: #666;
}

</style>

<template>
  <section>

    <FramePoem
      v-if="item.photo != ''"
      :photo="'/img/' + item.photo"
      :text="item.text"
    ></FramePoem>

    <div
      v-if="item.text === 'error'"
    >
      <p class="error">Nie ma takiej tematyki wierszy!</p>
      <p class="error">Wybierz tematykę z powyższej listy.</p>
    </div>
    <div
      class="titles-container"
      v-else
    >
      <div
        v-for="(poem, index) in poems" :key="index"
        @click="setPoem(poem, index)"
      >
        <p class="title">{{ poem.title }}</p>
      </div>
    </div>

    <transition name="fade">
        <div
            v-if="poem != null"
            class="poem-bg"
            @click="closePoem()"
        ></div>
    </transition>

    <transition name="fade">
      <div
        v-if="poem != null"
        class="poem-container"
        ref="poemContainer"
      >
        <div class="top-poem-nav">
            <svg class="icon-magnify" @click="fontSizeUp()">
              <path fill="currentColor" d="M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,7L13,12.07L14.41,13.5L17,10.9V17H19V10.9L21.59,13.5L23,12.07L18,7Z" />
            </svg>
            <svg class="icon-magnify" @click="fontSizeDown()">
              <path fill="currentColor" d="M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,17L23,11.93L21.59,10.5L19,13.1V7H17V13.1L14.41,10.5L13,11.93L18,17Z" />
            </svg>
          <div
            class="close-poem-btn"
            @click="closePoem()"
          ><p>&#10006;</p></div>
        </div>
        <div class="poem-wrapper" ref="wrapper">
          <p
            class="title-display"
            :style="{fontSize: titleFontSize + 'rem'}"
          >{{ poem.title }}</p>
          <div class="text-display-box">
            <p
              v-html="poemText"
              class="text-display"
              id="text-display"
              :style="{fontSize: textFontSize + 'rem'}"
            ></p>
            
          </div>
        </div>
        <div class="poems-nav">
          <transition name="fade">
            <div
              class="btn-left"
              v-if="prevPoem != null"
              @click="setPrevPoem()"
            >
              <p class="nav-btn">poprzedni</p>
              <p>{{ prevPoem.title }}</p>
            </div>
          </transition>
          <transition name="fade">
            <div
              class="btn-right"
              v-if="nextPoem != null"
              @click="setNextPoem()"
            >
              <p class="nav-btn">następny</p>
              <p>{{ nextPoem.title }}</p>
            </div>
          </transition>
        </div>
      </div>
    </transition>
    
  </section>
</template>

<script>
import FramePoem from '@/components/FramePoem'

export default {
  name: 'PoemsBox',
  components: {FramePoem},
  data: () => ({
    poem: null,
    poemIndex: null,
    prevPoem: null,
    prevPoemIndex: null,
    nextPoem: null,
    nextPoemIndex: null,
    titleFontSize: 2.5,
    textFontSize: 1.6,
  }),
  computed: {
    theme () {
      return this.$route.params.theme
    },
    newestOptions () {
      return this.$store.state.data.poems.newest
    },
    item () {
      let themes = this.$store.state.data.poems.themes
      if (themes.length == 1 && themes[0].name === '') {
        return {
          photo: '',
          text: ''
        }
      }
      else if (this.theme === this.$store.state.data.poems.newest.link) {
        return this.$store.state.data.poems.newest
      }
      else {
        let _item = themes.find(el => el.link === this.theme)
        if (_item == undefined)  {
          return {
            photo: '',
            text: 'error'
          }
        }
        else {
          return _item
        }
      }
    },
    poems () {
      return this.$store.state.poems.filter(el => {
        return this.theme === this.newestOptions.link ? el.newest === true : el.link === this.theme
      })
    },
    poemText () {
      if (this.poem != null) {
        return this.poem.text.replace(/\n/g, '<br>') + '<span style="display: block; font-size: 1.2rem; font-style: italic; margin-top: 25px; text-align: right;">Robert Iwanowski, ' + this.poem.date + '</span>'
      }
      else {
        return ''
      }
    }
  },
  methods: {
    setPoem (poem, index) {
      this.poem = poem
      this.poemIndex = index
      if (poem != null && index > 0) {
        this.prevPoem = this.poems[index - 1]
        this.prevPoemIndex = index - 1
      }
      else {
        this.prevPoem = null
        this.prevPoemIndex = null
      }
      if (poem != null && index < this.poems.length - 1) {
        this.nextPoem = this.poems[index + 1]
        this.nextPoemIndex = index + 1
      }
      else {
        this.nextPoem = null
        this.nextPoemIndex = null
      }
      document.body.style.overflowY = 'hidden'
    },
    closePoem () {
      document.body.style.overflowY = 'visible'
      this.poem = null
      this.poemIndex = null
      this.prevPoem = null
      this.prevPoemIndex = null
      this.nextPoem = null
      this.nextPoemIndex = null
    },
    setPrevPoem () {
      this.$refs.poemContainer.scrollTo({top: 0, behavior: 'smooth'})
      setTimeout(() => {
        this.$refs.wrapper.style.transform = 'translateX(-50px)'
        this.$refs.wrapper.style.opacity = 0
        setTimeout(() => {
          this.setPoem(this.prevPoem, this.prevPoemIndex)
          this.$refs.wrapper.style.transform = 'translateX(0)'
          this.$refs.wrapper.style.opacity = 1
        }, 500)
      }, 500)
    },
    setNextPoem () {
      this.$refs.poemContainer.scrollTo({top: 0, behavior: 'smooth'})
      setTimeout(() => {
        this.$refs.wrapper.style.transform = 'translateX(50px)'
        this.$refs.wrapper.style.opacity = 0
        setTimeout(() => {
          this.setPoem(this.nextPoem, this.nextPoemIndex)
          this.$refs.wrapper.style.transform = 'translateX(0)'
          this.$refs.wrapper.style.opacity = 1
        }, 500)
      }, 500)
    },
    fontSizeUp () {
      this.titleFontSize = this.titleFontSize + 0.2
      this.textFontSize = this.textFontSize + 0.2
    },
    fontSizeDown () {
      this.titleFontSize = this.titleFontSize - 0.2
      this.textFontSize = this.textFontSize - 0.2
    }
  }
}
</script>

<style scoped lang="scss">

section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.titles-container {
  padding: 0 30px;
  @media screen and (min-width: 960px) {
    transform: translateY(-40px);
  }
}
.title {
  font-weight: 300;
  margin: 10px 15px;
  padding: 0 10px;
  transition: all .15s linear;
  border-left: 0px solid #EB7054;
  @media screen and (min-width: 600px) {
    width: 300px;
    &:hover {
      color: #EB7054;
      cursor: pointer;
      border-left: 10px solid #EB7054;
    }
  }
}
.poem-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width: 800px;
  max-height: 900px;
  width: 90vw;
  height: 90vh;
  background-color: white;
  box-shadow: 0px 5px 15px 5px #444;
  z-index: 300;
  overflow-y: scroll;
  overflow-x: hidden;
}
.poem-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  transition: all .25s ease-in-out;
  flex-grow: 1;
}
.poem-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
}
.top-poem-nav {
    position: sticky;
    top: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 500;
}
.icon-magnify {
  width: 40px;
  height: 50px;
  color: #444;
  transition: all .25s linear;
  @media screen and (min-width: 1024px) {
      &:hover {
          cursor: pointer;
          color: #EB7054;
      }
    }
}
.close-poem-btn {
    width: 40px;
    height: 70px;
    font-size: 3rem;
    color: #444;
    transition: all .25s linear;
    margin-left: 15px;
    @media screen and (min-width: 1024px) {
      &:hover {
          cursor: pointer;
          color: #EB7054;
      }
    }
}
.title-display {
  font-weight: 300;
  font-size: 2.5rem;
  margin: 0 15px 20px 15px;
  text-align: center;
  transition: all .15s linear;
}
.text-display-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-display {
  margin: 0 15px 30px 15px;
  display: inline-block;
  line-height: 2.5rem;
  transition: all .15s linear;
}
.poems-nav {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 15px;
  div {
    text-align: center;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 600px) {
    flex-direction: row;
    div {
      flex-basis: 50%;
      padding: 10px 15px;
      transition: all .25s linear;
      height: 100%;
      max-height: 80px;
      &:hover {
        background-color: #EB7054;
        color: white;
        cursor: pointer;
      }
    }
    .btn-left {
      text-align: left;
      margin-left: 25px;
      margin: 15px auto 5px 0;
      padding-left: 30px;
    }
    .btn-right {
      text-align: right;
      margin-right: 25px;
      margin: 15px 0 5px auto;
      padding-right: 30px;
    }
  }
}
.nav-btn {
  font-style: italic;
  font-size: 1rem;
}
.error {
  color: red;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 15px;
}

</style>
